import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    focusOnSelect: true,
};

class BirthdayPartiesSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        const { galleryImages } = this.props;
        return (
            <>
                <Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    {...dotsSettings}
                    className="carousel-indicators"
                >
                    {galleryImages.map(image => (
                        <li key={image.id}></li>
                    ))}
                </Slider>
                <Slider
                    {...settings}
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    className="carousel-inner"
                >
                    {galleryImages.map(image => (
                        <div
                            className="item carousel-item active"
                            key={image.id}
                        >
                            <img
                                src={image.localFile.childImageSharp.fluid.src}
                                alt="bday slide 1"
                            />
                        </div>
                    ))}
                </Slider>
            </>
        );
    }
}

export default BirthdayPartiesSlider;
